<template>
  <div class="custom-req-wrapper md-layout-item">
    <h2 class="reveal-text">Lots of pictures?</h2>
    <h4>Contact us for custom prices if you have batch processing needs.</h4>
    <form name="contact" class="md-layout" netlify data-netlify="true" method="POST">
      <md-field>
        <label for="email0">Email</label>
        <md-input type="email" id="email0" name="email0" v-model="email" />
      </md-field>
      <md-field>
        <label for="needs">Nr. of Faces to process</label>
        <md-input type="number" id="needs" name="needs" v-model="needsNr" />
      </md-field>
      <p>
        <md-button type="submit" class="md-raised md-primary" @click.prevent="onSubscribe">
          {{ loading ? 'Sending...' : 'Request' }}
        </md-button>
      </p>
    </form>
  </div>
</template>
<script type="text/babel">
export default {
  name: 'Fac3dCustomRequest',
  data() {
    return {
      email: '',
      needsNr: 0,
      loading: false,
    };
  },
  methods: {
    onSubscribe() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2500);
    },
  },
};
</script>
<style lang="scss">
.custom-req-wrapper {
  padding: 1.5rem;
  background: rgba(0, 0, 0, .3);
  margin: 3rem;
  border-radius: 12px;
  h4, h2 {
    color: white;
  }
}
.md-dialog-content {
  .custom-req-wrapper {
    margin: 0;
  }
}
</style>
